import { z } from 'zod';

import IDesign from '@/types/IDesign';

import { ILinkedActivationKey } from './IActivationKey';
import IFile, { FileSchema } from './IFile';
import { IOrganisationSettings } from './IOrganisation';
import { VariantValue } from './IProduct';
import { TaptSocials } from './TaptSocials';

export interface IProfileSocialLink {
  order: number;
  type: TaptSocials;
  value: string;
  personal: boolean;
  type_from?: TaptSocials | null;
  value_from?: string | null;
  type_to?: TaptSocials | null;
  value_to?: string | null;
}

export interface IProfileQuickLink {
  order: number;
  title: string;
  value: string;
  title_from?: string | null;
  value_from?: string | null;
  title_to?: string | null;
  value_to?: string | null;
}

export interface IProfileFileLink {
  file_id: number;
  file?: IFile;
  file_from?: IFile;
  order: number;
  title: string;
  title_from?: string | null;
  file_id_from?: number | null;
  title_to?: string | null;
  file_id_to?: number | null;
}

export interface IProfileLink {
  label: string;
  order: number;
  href: string;
  linkType: 'file' | 'link';
}

export enum IProfileAppAccess {
  not_connected = 'not_connected',
  pending = 'pending',
  connected = 'connected',
}

type ProfileProduct = {
  type: VariantValue;
  card_hash: string;
};

export interface IProfileBase {
  id: number;
  profile_hash: string;
  status: string;
  email: string | null;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  photo: IFile | null;
  job_title: string | null;
  group_id: number | null;
  group_hash: string | null;
  group_name: string | null;
  group_subheading: string | null;
  app_access: IProfileAppAccess;
  editable: boolean;
  products: ProfileProduct[];
  inserted_at: string;
}

export default interface IProfile extends IProfileBase {
  city: string | null;
  company_name: string | null;
  company_phone_number: string | null;
  extra_company_phone_numbers: string[] | null;
  company_website: string | null;
  contact_exchange_enabled: boolean;
  country: string | null;
  design_id?: number;
  design?: IDesign;
  extra_emails: string[] | null;
  is_editable: boolean;
  pronouns: string | null;
  linked_card_activation?: ILinkedActivationKey[];
  linked_card_id?: number[];
  mobile_number: string | null;
  extra_mobile_numbers: string[] | null;
  note: string | null;
  order_uuid: string | null;
  organisation_id: number;
  organisation_name: string;
  photo_file_id: number | null;
  post_code: string | null;
  profile_file_links: IProfileFileLink[];
  profile_page_url: string;
  profile_public_url: string | null;
  profile_quick_links: IProfileQuickLink[];
  profile_social_links: IProfileSocialLink[];
  state: string | null;
  street_address: string | null;
  type: string | null;
  replacement_profile_id: number | null;
  replacement_profile_hash: string | null;
  map_enabled: boolean;
  location: [lat: number, lng: number] | null;
  settings: IProfileSettings | null;
  settings_level: 'organisation' | 'profile';
}

export interface IProfilePublic {
  analytics_view_count: number;
  city: string | null;
  company_name: string | null;
  company_phone_number: string | null;
  extra_company_phone_numbers: string[] | null;
  company_website: string | null;
  contact_exchange_enabled: boolean;
  country: string | null;
  design_id?: number;
  design?: IDesign;
  email: string | null;
  extra_emails: string[] | null;
  first_name: string | null;
  id?: number;
  inserted_at?: string | null;
  is_editable: boolean;
  job_title: string | null;
  last_name: string | null;
  pronouns: string | null;
  linked_card_id?: number[];
  middle_name: string | null;
  mobile_number: string | null;
  extra_mobile_numbers: string[] | null;
  note: string | null;
  organisation_id: number;
  organisation_name: string;
  photo_file_id?: number | null;
  photo?: IFile;
  post_code: string | null;
  profile_file_links: IProfileFileLink[];
  profile_hash?: string | null;
  profile_public_url?: string | null;
  profile_quick_links: IProfileQuickLink[];
  profile_social_links: IProfileSocialLink[];
  settings: IOrganisationSettings;
  state: string | null;
  status: string | null;
  street_address: string | null;
  type?: string | null;
  vcf_url: string;
  add_contact_page: string;
  group_id: number | null;
  group_name: string | null;
  group_subheading: string | null;
}

export interface IProfileSignature {
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  job_title: string | null;
  email: string | null;
  mobile_number: string | null;
  photo?: IFile;
  profile_page_url: string;
  company_name: string | null;
  company_website: string | null;
  company_phone_number: string | null;
  country: string | null;
  street_address: string | null;
  city: string | null;
  state: string | null;
  post_code: string | null;
  profile_social_links: IProfileSocialLink[];
}

export interface IProfileSettings {
  bg_color: string;
  button_bg_color: string;
  button_text_color: string;
  text_color: string;
  logo: IFile | null;
  logo_file_id: number | null;
}

export const ProfileSettingsSchema = z.object({
  bg_color: z.string(),
  text_color: z.string(),
  button_bg_color: z.string(),
  button_text_color: z.string(),
  logo: FileSchema.nullable(),
  logo_file_id: z.number().nullable(),
});
