import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useBlocker, useNavigate } from 'react-router-dom';

import { Tab } from '@headlessui/react';
import { EyeIcon } from '@heroicons/react/20/solid';

import organisationsAPI from '@/api/organisations';
import profilesAPI from '@/api/profiles';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import ColorPicker from '@/components/ColorPicker';
import { AddProfilesModal } from '@/components/GroupPage/AddProfilesModal';
import { DeleteGroup } from '@/components/GroupPage/DeleteGroup';
import { GroupEditingAccess } from '@/components/GroupPage/GroupEditingAccess';
import { RemoveProfilesModal } from '@/components/GroupPage/RemoveProfilesModal';
import { InfoPanelContainer } from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Layout from '@/components/Layout';
import Modal from '@/components/Modals/Modal';
import { ProfilePreviewModal } from '@/components/ProfilePreview';
import TabList from '@/components/TabList';
import { UnsavedChangesModal } from '@/components/UnsavedChangesPrompt';
import UploadPhoto from '@/components/UploadPhoto';
import COLORS from '@/constants/colors';
import { COVER_IMAGE } from '@/constants/files';
import MESSAGES from '@/constants/messages-en';
import GroupInfoForm from '@/containers/GroupInfoForm';
import useAuth from '@/hooks/useAuth';
import IFile from '@/types/IFile';
import { IOrganisationUser } from '@/types/IOrganisation';

const tabs = [
  {
    value: 'general',
    name: 'General',
  },
  {
    value: 'group-template',
    name: 'Group Template',
  },
];

export default function EditGroupPage() {
  const { orgID, userRole } = useAuth();
  const navigate = useNavigate();
  const [, , groupHash] = window.location.pathname.split('/');

  const [isDirty, setIsDirty] = useState(false);
  const blocker = useBlocker(isDirty);

  const {
    mutateAsync: updateGroup,
    isLoading: isUpdatingGroup,
    isError: isUpdatingGroupError,
  } = useMutation({
    mutationFn: updateOrganisationGroup,
    onSuccess: () => {
      navigate('/groups', {
        state: {
          success: 'Group has been updated successfully.',
        },
      });
    },
  });

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const [groupID, setGroupID] = useState<number | undefined>(undefined);
  const [groupName, setGroupName] = useState('');
  const [subheading, setSubheading] = useState('');
  const [backgroundColor, setBackgroundColor] = useState(
    COLORS.organisation.defaultProfile.backgroundColor,
  );
  const [textColor, setTextColor] = useState(
    COLORS.organisation.defaultProfile.textColor,
  );
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState(
    COLORS.organisation.defaultProfile.buttonBackgroundColor,
  );
  const [buttonTextColor, setButtonTextColor] = useState(
    COLORS.organisation.defaultProfile.buttonTextColor,
  );
  const [groupCoverImageFile, setGroupCoverImageFile] = useState<
    IFile | undefined
  >(undefined);

  const [groupEditors, setGroupEditors] = useState<
    IOrganisationUser[] | undefined
  >(undefined);
  const [profilesCount, setProfilesCount] = useState(0);
  const [addedProfiles, setAddedProfiles] = useState<Set<number>>(new Set());
  const [removedProfiles, setRemovedProfiles] = useState<Set<number>>(
    new Set(),
  );
  const [profilesSuccess, setProfilesSuccess] = useState<string | undefined>(
    undefined,
  );

  const [isAddProfilesModalOpen, setIsAddProfilesModalOpen] = useState(false);
  const [isRemoveProfilesModalOpen, setIsRemoveProfilesModalOpen] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [groupNameError, setGroupNameError] = useState(false);

  const groupCoverImageFileID = groupCoverImageFile
    ? groupCoverImageFile.id
    : null;

  async function showOrganisationGroup() {
    if (orgID === undefined || groupHash === undefined) {
      return;
    }

    try {
      setIsLoading(true);

      const {
        data: { data: orgGroup },
      } = await organisationsAPI.showOrganisationGroup(orgID, groupHash);

      setGroupID(orgGroup.id);
      setGroupName(orgGroup.name);
      if (orgGroup.description) {
        setSubheading(orgGroup.description);
      }
      setGroupEditors(orgGroup.editors);
      setProfilesCount(orgGroup.profiles_count);

      setGroupCoverImageFile(orgGroup.group_logo);
      setBackgroundColor(orgGroup.bg_color);
      setTextColor(orgGroup.text_color);
      setButtonBackgroundColor(orgGroup.button_bg_color);
      setButtonTextColor(orgGroup.button_text_color);
    } catch (error: unknown) {
      navigate('/404');
    } finally {
      setIsLoading(false);
    }
  }

  async function updateOrganisationGroup() {
    if (orgID === undefined || groupID === undefined) {
      throw new Error('Group ID cannot be empty');
    }

    if (groupName === '') {
      setGroupNameError(true);
      throw new Error('Group name cannot be blank');
    }

    if (groupNameError) {
      setGroupNameError(false);
    }

    setIsDirty(false);

    await organisationsAPI.updateOrganisationGroup(orgID, groupID, {
      organisation_group: {
        name: groupName,
        description: subheading,
        group_logo_file_id: groupCoverImageFileID,
        bg_color: backgroundColor,
        text_color: textColor,
        button_bg_color: buttonBackgroundColor,
        button_text_color: buttonTextColor,
      },
    });

    if (userRole === 'org_admin') {
      await profilesAPI.updateProfilesShared({
        orgID,
        body: {
          ids: Array.from(addedProfiles),
          profile: { group_id: groupID },
        },
      });

      await profilesAPI.updateProfilesShared({
        orgID,
        body: {
          ids: Array.from(removedProfiles),
          profile: { group_id: null },
        },
      });
    }
  }

  useEffect(() => {
    showOrganisationGroup();
  }, []);

  const makeFormDirty = () => {
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const colors = [
    {
      name: 'Page background color',
      color: backgroundColor,
      setColor: setBackgroundColor,
    },
    {
      name: 'Text color',
      color: textColor,
      setColor: setTextColor,
    },
    {
      name: 'Button background color',
      color: buttonBackgroundColor,
      setColor: setButtonBackgroundColor,
    },
    {
      name: 'Button text color',
      color: buttonTextColor,
      setColor: setButtonTextColor,
    },
  ];

  const noOfProfiles =
    profilesCount + addedProfiles.size - removedProfiles.size;

  return (
    <Layout pageName="Edit Group" className="bg-gray-50">
      <Tab.Group
        defaultIndex={0}
        selectedIndex={selectedIndex}
        onChange={setSelectedIndex}
      >
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full border-b border-gray-200">
          <div className="w-full md:w-auto">
            <TabList tabs={tabs} value={tabs[selectedIndex].value} />
          </div>
        </div>
        <Tab.Panels>
          <Tab.Panel className="outline-none">
            <div className="mt-10 mb-14">
              {isUpdatingGroupError && !groupNameError && (
                <ErrorAlert message={MESSAGES.error.generic} />
              )}
              <InfoPanelContainer
                title="Group Information"
                description="Add details to better identify your group."
              >
                <div onFocusCapture={makeFormDirty}>
                  <GroupInfoForm
                    isLoading={isLoading}
                    groupName={groupName}
                    groupNameError={groupNameError}
                    subheading={subheading}
                    setGroupName={setGroupName}
                    setSubheading={setSubheading}
                  />
                </div>
              </InfoPanelContainer>

              {userRole === 'org_admin' && (
                <>
                  <InfoPanelDivider />
                  <InfoPanelContainer
                    title="Select Profiles"
                    description="Add profiles from your organisation to this group."
                  >
                    <div
                      className="flex flex-col xl:flex-row justify-between gap-2 xl:items-center"
                      onFocusCapture={makeFormDirty}
                    >
                      <div className="flex flex-col text-gray-900 text-sm gap-1">
                        Number of profiles in group
                        <span className="text-gray-500">
                          {noOfProfiles} profile
                          {noOfProfiles === 1 ? '' : 's'}
                        </span>
                      </div>
                      <div className="flex justify-end space-x-4">
                        {(profilesCount > 0 || removedProfiles.size > 0) && (
                          <Button
                            kind={BUTTON_KIND.WHITE}
                            buttonText="Remove profiles"
                            onClick={() => setIsRemoveProfilesModalOpen(true)}
                          />
                        )}
                        <Button
                          kind={BUTTON_KIND.PRIMARY}
                          disabled={isLoading}
                          buttonText="Add profiles"
                          onClick={() => setIsAddProfilesModalOpen(true)}
                        />
                      </div>
                    </div>
                    {profilesSuccess && (
                      <SuccessAlert message={profilesSuccess} />
                    )}
                    {groupID && (
                      <AddProfilesModal
                        groupID={groupID}
                        isOpen={isAddProfilesModalOpen}
                        setIsOpen={setIsAddProfilesModalOpen}
                        onSuccess={profileIDs => {
                          setAddedProfiles(profileIDs);
                          setProfilesSuccess(
                            `Profile${
                              profileIDs.size === 1 ? '' : 's'
                            } have been added to the group.`,
                          );
                        }}
                      />
                    )}
                    {groupID && (
                      <RemoveProfilesModal
                        groupID={groupID}
                        isOpen={isRemoveProfilesModalOpen}
                        setIsOpen={setIsRemoveProfilesModalOpen}
                        onSuccess={profileIDs => {
                          setRemovedProfiles(profileIDs);
                          setProfilesSuccess(
                            `Profile${
                              profileIDs.size === 1 ? '' : 's'
                            } have been removed from the group.`,
                          );
                        }}
                      />
                    )}
                  </InfoPanelContainer>
                  <InfoPanelDivider />
                  <InfoPanelContainer
                    title="Group Editing Access"
                    description="These users will have access to the group and be able to manage it."
                  >
                    <div onFocusCapture={makeFormDirty}>
                      <GroupEditingAccess
                        groupID={groupID}
                        editors={groupEditors}
                        newInvitees={[]}
                        callback={showOrganisationGroup}
                      />
                    </div>
                  </InfoPanelContainer>
                  <InfoPanelDivider />
                  <InfoPanelContainer
                    title="Delete Group"
                    description="Please note the irreversible nature of this action. By deleting this group, you will be rendering it unusable. Profiles will not be deleted."
                  >
                    <DeleteGroup groupID={groupID} />
                  </InfoPanelContainer>
                </>
              )}
              <Modal
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
                dialogTitle="Remove profiles and managers from group"
                dialogDescription="This group has profiles and/or group managers. If you want to delete this group, you must first remove all profiles and/or group managers from this group."
                successButtonText="Remove profiles from group"
                successButtonKind={BUTTON_KIND.PRIMARY}
                onSuccess={() => {
                  setIsDeleteModalOpen(false);
                  setIsRemoveProfilesModalOpen(true);
                }}
                isLoading={isLoading}
              />
            </div>
          </Tab.Panel>
        </Tab.Panels>
        <Tab.Panels>
          <Tab.Panel className="outline-none">
            <div
              className="mt-10 mb-14 pb-20 md:pb-0"
              onFocusCapture={makeFormDirty}
            >
              <InfoPanelContainer
                title="Profile Page Appearance"
                description="Choose an image to display at the top of profile pages of cardholders in this group. Choose custom colour to reflect your company's branding."
              >
                <div className="flex flex-col">
                  <span className="font-medium text-xl">Cover image</span>
                  <span className="text-gray-500 text-sm">
                    Choose an image to display at the top of digital profiles.
                  </span>
                </div>
                <div className="flex items-center pt-3">
                  <UploadPhoto
                    title="Cover image"
                    photo={groupCoverImageFile}
                    setPhoto={setGroupCoverImageFile}
                    size="large"
                    aspectRatio={16 / 11}
                    fileFormatMessage="Recommended dimensions 1024px x 704px"
                    loading={isLoading}
                    maxHeight={COVER_IMAGE.MAX_HEIGHT}
                    maxWidth={COVER_IMAGE.MAX_WIDTH}
                  />
                </div>
                <div className="font-medium text-xl text-gray-900 py-4">
                  Colors
                  <p className="text-gray-500 text-sm font-normal pt-1">
                    Create a custom theme for cardholder profile pages. Maintain
                    good readability by ensuring there is sufficient contrast
                    between text and background colours.
                  </p>
                </div>
                <div className="flex flex-col xl:flex-row xl:items-end justify-between gap-6 xl:gap-0">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {colors.map((color, index) => (
                      <div key={index} className="col-span-1">
                        <p className="mb-2 text-sm leading-5 font-medium text-gray-900">
                          {color.name}
                        </p>
                        <ColorPicker
                          color={color.color}
                          setColor={color.setColor}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="self-end">
                    <Button
                      buttonText="Preview"
                      kind={BUTTON_KIND.PRIMARY}
                      icon={<EyeIcon />}
                      onClick={() => setIsPreviewOpen(true)}
                    />
                    <ProfilePreviewModal
                      isOpen={isPreviewOpen}
                      setIsOpen={setIsPreviewOpen}
                      settings={{
                        bgColor: backgroundColor,
                        textColor: textColor,
                        buttonBgColor: buttonBackgroundColor,
                        buttonTextColor: buttonTextColor,
                        companyLogo: groupCoverImageFile || null,
                      }}
                    />
                  </div>
                </div>
              </InfoPanelContainer>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <InfoPanelFooter>
        <div className="flex justify-end space-x-4">
          <Button
            className="flex-1 xl:flex-none"
            buttonText="Discard changes"
            kind={BUTTON_KIND.WHITE}
            onClick={() => navigate('/groups')}
          />
          <Button
            className="flex-1 xl:flex-none"
            buttonText="Save & close"
            loading={isUpdatingGroup}
            onClick={() => updateGroup()}
          />
        </div>
      </InfoPanelFooter>
      {blocker.state === 'blocked' && (
        <UnsavedChangesModal
          proceed={async () => {
            await updateGroup();
            blocker.proceed();
          }}
          cancel={blocker.proceed}
          reset={blocker.reset}
          isLoading={isUpdatingGroup}
        />
      )}
    </Layout>
  );
}
