import { z } from 'zod';

import { FileSchema } from '@/types/IFile';
import { ProfileSettingsSchema } from '@/types/IProfile';
import {
  ProfileFileLinkUnionSchema,
  ProfileQuickLinkSchema,
  ProfileSocialLinkSchema,
} from '@/types/TaptSocials';

export const PersonalInformationSchema = z.object({
  first_name: z.string().nullable(),
  job_title: z.string().nullable(),
  last_name: z.string().nullable(),
  middle_name: z.string().nullable(),
  mobile_number: z.string().nullable(),
  extra_emails: z
    .array(z.union([z.string().email(), z.literal('')]))
    .nullable(),
  email: z.union([z.string().email().nullable(), z.literal('')]),
  extra_mobile_numbers: z
    .array(z.union([z.string(), z.literal('')]))
    .nullable(),
  note: z.string().nullable(),
  photo: FileSchema.nullable(),
  pronouns: z.string().nullable(),
});

export type PersonalInformationForm = z.infer<typeof PersonalInformationSchema>;

export const CompanyInfoFormSchema = z.object({
  city: z.string().nullable(),
  company_name: z.string().nullable(),
  company_phone_number: z.string().nullable(),
  company_website: z.union([z.string().nullable(), z.literal('')]),
  country: z.string().nullable(),
  extra_company_phone_numbers: z
    .array(z.union([z.string(), z.literal('')]))
    .nullable(),
  state: z.string().nullable(),
  street_address: z.string().nullable(),
  post_code: z.string().nullable(),
});

export type CompanyInfoForm = z.infer<typeof CompanyInfoFormSchema>;

export const SocialLinksFormSchema = z.object({
  profile_social_links: z.array(ProfileSocialLinkSchema),
});

export type SocialLinksForm = z.infer<typeof SocialLinksFormSchema>;

export const QuickLinksFormSchema = z.object({
  profile_file_links: z.array(ProfileFileLinkUnionSchema),
  profile_quick_links: z.array(ProfileQuickLinkSchema),
});

export type QuickLinksForm = z.infer<typeof QuickLinksFormSchema>;

export const ContactExchangeFormSchema = z.object({
  contact_exchange_enabled: z.boolean(),
});

export type ContactExchangeForm = z.infer<typeof ContactExchangeFormSchema>;

export const ProfilePageAppearanceSchema = z.object({
  settings_level: z.union([z.literal('organisation'), z.literal('profile')]),
  settings: ProfileSettingsSchema,
});

export type ProfilePageAppearanceForm = z.infer<
  typeof ProfilePageAppearanceSchema
>;

export const WorkingProfileFormSchema = PersonalInformationSchema.merge(
  CompanyInfoFormSchema,
)
  .merge(SocialLinksFormSchema)
  .merge(QuickLinksFormSchema)
  .merge(ContactExchangeFormSchema)
  .merge(ProfilePageAppearanceSchema);

export type WorkingProfileForm = z.infer<typeof WorkingProfileFormSchema>;

export const SharedProfilePageSchema = CompanyInfoFormSchema.merge(
  SocialLinksFormSchema,
)
  .merge(QuickLinksFormSchema)
  .merge(ContactExchangeFormSchema)
  .merge(ProfilePageAppearanceSchema);

export type WorkingSharedProfilePageForm = z.infer<
  typeof SharedProfilePageSchema
>;

export const AdminProfilePageSchema = PersonalInformationSchema.merge(
  SocialLinksFormSchema,
)
  .merge(CompanyInfoFormSchema)
  .merge(ContactExchangeFormSchema)
  .merge(QuickLinksFormSchema)
  .merge(ContactExchangeFormSchema);

export type WorkingAdminProfilePageForm = z.infer<
  typeof AdminProfilePageSchema
>;
