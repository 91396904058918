import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';

import adminAPI from '@/api/admin';
import { CompanyInformation } from '@/components/EditProfilePage/CompanyInfoForm';
import { PersonalInformation } from '@/components/EditProfilePage/PersonalInfoForm';
import { ProfileQuickFileLinks } from '@/components/EditProfilePage/QuickLinksForm';
import { ProfileSocialLinks } from '@/components/EditProfilePage/SocialLinksForm';
import {
  AdminProfilePageSchema,
  WorkingAdminProfilePageForm,
} from '@/components/EditProfilePage/schema';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import Layout from '@/components/Layout';
import LoadingAnimation from '@/components/LoadingAnimation';
import MESSAGES from '@/constants/messages-en';

export default function EditProfileAdminPage() {
  const location = useLocation();
  const [, , profileID] = location.pathname.split('/');

  const methods = useForm<WorkingAdminProfilePageForm>({
    resolver: zodResolver(AdminProfilePageSchema),
  });

  const { data: profile } = useQuery(['showProfile', profileID], showProfile, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (profile) {
      methods.reset({
        city: profile.city,
        company_name: profile.company_name,
        company_phone_number: profile.company_phone_number,
        company_website: profile.company_website,
        country: profile.country,
        contact_exchange_enabled: profile.contact_exchange_enabled,
        email: profile.email,
        extra_emails: profile.extra_emails,
        extra_mobile_numbers: profile.extra_mobile_numbers,
        extra_company_phone_numbers: profile.extra_company_phone_numbers,
        first_name: profile.first_name,
        job_title: profile.job_title,
        last_name: profile.last_name,
        middle_name: profile.middle_name,
        mobile_number: profile.mobile_number,
        note: profile.note,
        photo: profile.photo,
        post_code: profile.post_code,
        profile_file_links: profile.profile_file_links.map(link => ({
          status: 'complete',
          ...link,
        })),
        profile_quick_links: profile.profile_quick_links,
        profile_social_links: profile.profile_social_links,
        pronouns: profile.pronouns,
        state: profile.state,
        street_address: profile.street_address,
      });
    }
  }, [profile]);

  async function showProfile() {
    const { data } = await adminAPI.showProfile(parseInt(profileID));

    return data.data;
  }

  if (profile === undefined) {
    return (
      <Layout
        pageName="Edit Profile"
        description={MESSAGES.profile.edit.heading}
        className="bg-gray-50"
      >
        <div className="flex items-center justify-center h-full">
          <LoadingAnimation className="w-16 h-16 mx-auto" />
        </div>
      </Layout>
    );
  }

  return (
    <FormProvider {...methods}>
      <Layout
        pageName="Edit Profile"
        className="bg-gray-50"
        description={MESSAGES.profile.edit.heading}
      >
        <PersonalInformation disabled />
        <InfoPanelDivider />
        <ProfileSocialLinks disabled />
        <InfoPanelDivider />
        <CompanyInformation disabled />
        <InfoPanelDivider />
        <ProfileQuickFileLinks disabled />
      </Layout>
    </FormProvider>
  );
}
