import { useFormContext } from 'react-hook-form';

import ColorPicker from '@/components/ColorPicker';
import { InfoPanelContainer } from '@/components/InfoPanelContainer';
import ToggleField from '@/components/ToggleField';
import UploadPhoto from '@/components/UploadPhoto';
import { COVER_IMAGE } from '@/constants/files';

import { ProfilePageAppearanceForm } from './schema';

export function ProfilePageAppearance() {
  const { watch, setValue } = useFormContext<ProfilePageAppearanceForm>();

  const settingsLevel = watch('settings_level');
  const isCustomDesignEnabled = settingsLevel === 'profile';

  const logo = watch('settings.logo');

  const colorPickers = [
    {
      title: 'Background colour',
      value: watch('settings.bg_color'),
      setter: (color: string) => setValue('settings.bg_color', color),
    },
    {
      title: 'Text colour',
      value: watch('settings.text_color'),
      setter: (color: string) => setValue('settings.text_color', color),
    },
    {
      title: 'Button colour',
      value: watch('settings.button_bg_color'),
      setter: (color: string) => setValue('settings.button_bg_color', color),
    },
    {
      title: 'Button text colour',
      value: watch('settings.button_text_color'),
      setter: (color: string) => setValue('settings.button_text_color', color),
    },
  ];

  return (
    <InfoPanelContainer
      title="Profile page appearance"
      description="Toggle this on to apply a profile-specific design, overriding the company template design."
    >
      <div className="space-y-6">
        <ToggleField
          label="Assign a custom profile page design to this profile holder"
          enabled={isCustomDesignEnabled}
          setter={enabled =>
            setValue('settings_level', enabled ? 'profile' : 'organisation')
          }
        />
        {isCustomDesignEnabled && (
          <>
            <hr className="bg-gray-300" />
            <UploadPhoto
              title="Cover image"
              photo={logo ?? undefined}
              setPhoto={logo => {
                setValue('settings.logo', logo ?? null);
                setValue('settings.logo_file_id', logo?.id ?? null);
              }}
              aspectRatio={16 / 11}
              fileFormatMessage="Recommended dimensions 1024px x 704px"
              maxHeight={COVER_IMAGE.MAX_HEIGHT}
              maxWidth={COVER_IMAGE.MAX_WIDTH}
              size="large"
            />
            <div className="grid grid-cols-2 gap-5 w-full max-w-2xl">
              {colorPickers.map((picker, index) => (
                <div key={index} className="space-y-3">
                  <span className="block text-sm leading-5 font-medium text-gray-900">
                    {picker.title}
                  </span>
                  <ColorPicker color={picker.value} setColor={picker.setter} />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </InfoPanelContainer>
  );
}
